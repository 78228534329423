import useCanvas from 'hooks/useCanvas'
import { useCallback } from 'react'

export default function Wave({ speed = 75, frequency = 5.5, fill = '#fff' }) {
  const canvasCallback = useCallback(
    (ctx, t) => {
      const { width: w, height: h } = ctx.canvas
      const amp = -h / 2
      const freq = frequency / w

      ctx.clearRect(0, 0, w, h)
      ctx.beginPath()

      for (let i = 0; i < w; i++) {
        ctx.lineTo(i, -amp + amp * Math.sin(i * freq + t / speed))
      }

      ctx.lineTo(w, h)
      ctx.lineTo(0, h)

      ctx.fillStyle = fill
      ctx.fill()
    },
    [speed, frequency, fill]
  )

  const canvasRef = useCanvas(canvasCallback)

  return <canvas className="w-full h-full" ref={canvasRef} />
}
